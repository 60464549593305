@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic&display=swap");

@import "~@fortawesome/fontawesome-free/css/all.min.css";

@import "src/styles/colors";
@import "src/styles/dimensions";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --mainColor: #fc7300;
  --mainColorLight: #f89a3663;
  --secondaryColor: #ffffff;
  font-size: 16px;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Open Sans", "Noto Sans Arabic", sans-serif;
  scrollbar-width: thin;
  scrollbar-color: rgba($primary, 0.5) #f1f1f1;
}

@layer utilities {
  .flex-center {
    @apply flex justify-center items-center;
  }
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgrey;
  border-radius: 20px;
  border: 1px solid lightgrey;
}

/* Set the width of the scrollbar */
::-webkit-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba($primary, 0.5) #f1f1f1;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba($primary, 0.5);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba($primary, 0.8);
}

/* Set the color of the scrollbar buttons on hover */
::-webkit-scrollbar-button:hover {
  background-color: #bbb;
}

/* Track */
::-moz-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-moz-scrollbar-thumb {
  background-color: rgba($primary, 0.5);
  border-radius: 10px;
}

/* Handle on hover */
::-moz-scrollbar-thumb:hover {
  background-color: rgba($primary, 0.8);
}

/* Set the color of the scrollbar buttons on hover */
::-moz-scrollbar-button:hover {
  background-color: #bbb;
}

.base-layout {
  display: grid;
  grid-template-rows: $nav-height minmax(
      calc(100vh - #{$nav-height}),
      max-content
    );
  grid-template-columns: 100%;
  grid-template-areas:
    "nav"
    "cont";
}

// .page-content {
//   grid-area: cont;
//   width: 100%;
//   max-width: 1400px;
//   margin-inline: auto;
//   padding: 3vw;
//   padding-top: 0;
//   @media screen and (max-width: 450px) {
//     padding-inline: 0;
//   }
// }
.page-content {
  grid-area: cont;

  width: 100%;
  max-width: 1400px;
  margin-inline: auto;
}

$font-size-base: 1rem;

$font-size-upper: 2.5rem;
$font-weight-upper: 700;
$font-size-dec: 0.25rem;

@for $i from 1 to 6 {
  h#{$i} {
    font-size: $font-size-upper;
    font-size: ($font-size-upper / $font-size-base) + rem;
    @if $i < 4 {
      font-weight: $font-weight-upper;
    } @else {
      font-weight: $font-weight-upper - 200;
    }
  }
  $font-size-upper: $font-size-upper - $font-size-dec;
}

.loading-spinner-continer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  font-size: 50px;
  margin: auto;
  color: var(--mainColor);
}
.loading-small-spinner-continer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  margin: auto;
  color: var(--mainColor);
}

.page-layout {
  display: grid;
  grid-template-rows: 60px minmax(calc(100vh - 60px), 1fr) 275px;
  grid-template-columns: 100%;
  grid-template-areas: "nav" "cont" "foot";
  .page-content {
    grid-area: cont;
  }
}

@media (min-width: 30em) {
  .filepond--item {
    width: calc(50% - 0.5em);
  }
}

@media (min-width: 50em) {
  .filepond--item {
    width: calc(33.33% - 0.5em);
  }
}

.err {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  padding: 0px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-family: "Open Sans", "Noto Sans Arabic", sans-serif;
  font-weight: bold;
  margin-bottom: 5px;
}

.app-card-shadow {
  border-radius: 15px;
  box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
}
.page-wrapper {
  @apply max-w-[1150px] min-w-[300px] w-[80vw] mx-auto;

  .page-title {
    color: var(--mainColor);
    text-align: center;
    padding-block: 2rem;
    &,
    & * {
      text-transform: capitalize;
    }
  }
}
